<div class="page px-5" xmlns="http://www.w3.org/1999/html">

    <div class="page__headline" eciTranslate="carrierDetermination.subtitle">
        Carrier Percentage
    </div>

    <div class="page__filter mb-2">
        <div class="row">
            <div class="col-lg-3">
                <label class="col-form-label me-2"
                    eciTranslate="carrierDetermination.menu.percentage.country">Countries</label>
                <dx-tag-box [dataSource]="possibleCountries"
                        (onValueChanged)="onFilterChange()"
                        [valueExpr]="'id'"
                        [displayExpr]="'name'"
                        [(value)]="selectedCountries"
                        [searchEnabled]="true">
                </dx-tag-box>
            </div>
            <div class="col-lg-3">
                <label class="col-form-label me-2" eciTranslate="carrierDetermination.menu.percentage.warehouseID">Warehouses</label>
                <dx-tag-box [dataSource]="possibleWarehouses"
                            (onValueChanged)="onFilterChange()"
                            [(value)]="selectedWarehouses"
                            [valueExpr]="'id'"
                            [displayExpr]="'name'"
                            [searchEnabled]="true">
                </dx-tag-box>
            </div>
            <div class="col-lg-3">
                <label class="col-form-label me-2"
                    eciTranslate="carrierDetermination.menu.percentage.carrier">Carriers</label>
                <dx-tag-box [dataSource]="possibleCarriers"
                            (onValueChanged)="onFilterChange()"
                            [(value)]="selectedCarriers"
                            [valueExpr]="'id'"
                            [displayExpr]="'name'"
                            [searchEnabled]="true">
                </dx-tag-box>
            </div>
        </div>
    </div>

    <div class="row">

        <div class="col-md-12">
            <dx-data-grid (onRowUpdating)="rowUpdate($event)"
                          (onRowInserting)="rowInsert($event)"
                          (onInitNewRow)="initRow($event)"
                          (onEditingStart)="editRow()"
                          (onToolbarPreparing)="onToolbarPreparing($event)"
                          (onEditorPreparing)="onEditorPreparing($event)"
                          [rowAlternationEnabled]="true"
                          [allowColumnReordering]="true"
                          [allowColumnResizing]="true"
                          [cellHintEnabled]="true"
                          [dataSource]="dataSource"
                          [hoverStateEnabled]="true"
                          [remoteOperations]="{ filtering: true, sorting: true, paging: true }"
                          [showBorders]="true"
                          [showColumnLines]="true"
                          [showRowLines]="true"
                          [wordWrapEnabled]="true">

                <div *dxTemplate="let data of 'gridHeader'">
                    <div class="d-flex align-items-left">
                        <dx-button stylingMode="outlined"
                                   icon="fa-sharp fa-solid fa-filter-circle-xmark"
                                   type="danger"
                                   (onClick)="resetFilter()">
                        </dx-button>
                    </div>
                </div>

                <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showInfo]="true" [visible]="true" [showPageSizeSelector]="true"
                           [allowedPageSizes]="allowedPageSizes"></dxo-pager>
                <dxo-load-panel [enabled]="true"></dxo-load-panel>
                <dxo-editing
                    *eciHasAnyAuthority="Privilege.WriteCarrierAllocation"
                    mode="form"
                    [useIcons]="true"
                    [allowUpdating]="true"
                    [allowDeleting]="true"
                    [allowAdding]="true">
                    <dxo-form [colCount]=2>
                        <dxi-item itemType="group" [colCount]=2 [colSpan]=2>
                            <dxi-item itemType="group" [colSpan]=1>
                                <dxi-item dataField="countryId"></dxi-item>
                                <dxi-item dataField="warehouseId"></dxi-item>
                            </dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]=2 [colSpan]=2>
                            <dxi-item itemType="group">
                                <dxi-item dataField="salesChannel"></dxi-item>
                                <dxi-item dataField="carrierId" ></dxi-item>
                            </dxi-item>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]=2 [colSpan]=2>
                            <dxi-item itemType="group">
                                <dxi-item dataField="carrierProductId"></dxi-item>
                                <dxi-item dataField="deliveryOption"></dxi-item>
                            </dxi-item>
                            <dxi-item itemType="group" [colCount]=2 [colSpan]=1>
                                <dxi-item dataField="maximum"></dxi-item>
                                <dxi-item dataField="percentageAllocation"></dxi-item>
                                <dxi-item dataField="activeSlotStart" [colSpan]=1></dxi-item>
                                <dxi-item dataField="activeSlotEnd" [colSpan]=1></dxi-item>
                            </dxi-item>
                        </dxi-item>
                        <dxi-item itemType="empty" [colSpan]=2></dxi-item>

                        <dxi-item itemType="group" [colSpan]=2>
                            <dxi-item alignment="right" [template]="'formButtons'"></dxi-item>
                            <div *dxTemplate="let data of 'formButtons'">
                                <div class="modal-footer">
                                    <dx-check-box [text]="'shared.forms.createAnother' | translate"
                                                  [(value)]="createAnother" class="me-3"></dx-check-box>
                                    <button (click)="cancel()" [disabled]="isSaving" class="btn btn-secondary me-2"
                                            role="button" type="button">
                                        <i aria-hidden="true" class="fas fa-ban me-1"></i>
                                        <span eciTranslate="shared.forms.cancel">Cancel</span>
                                    </button>
                                    <button (click)="save()" [disabled]="isSaving" class="btn btn-primary me-2" role="button"
                                            type="submit">
                                        <i *ngIf="!isSaving" aria-hidden="true" class="fas fa-save me-1"></i>
                                        <i *ngIf="isSaving" aria-hidden="true"
                                           class="fas fa-spinner fa-spin fa-fw me-1"></i> <span
                                        eciTranslate="shared.forms.save">Save</span>
                                    </button>
                                </div>
                            </div>
                        </dxi-item>

                    </dxo-form>
                </dxo-editing>

                <dxi-column [calculateFilterExpression]="calculateCountryFilterExpression"
                            [setCellValue]="resetCarrierProductCell"
                            dataField="countryId"
                            sortOrder="asc"
                            calculateSortValue="countryName"
                            [caption]="'carrierDetermination.menu.percentage.country' | translate">
                    <dxo-lookup [dataSource]="countryStore" valueExpr="id" displayExpr="name"></dxo-lookup>
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateWarehouseFilterExpression"
                            dataField="warehouseId"
                            [caption]="'carrierDetermination.menu.percentage.warehouseID' | translate">
                    <dxo-lookup [dataSource]="warehouseStore"
                                valueExpr="id"
                                displayExpr="name"></dxo-lookup>
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateCarrierFilterExpression"
                            [setCellValue]="resetCarrierProductCell"
                            dataField="carrierId"
                            [caption]="'carrierDetermination.menu.percentage.carrier' | translate">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="getFilteredCarriers"
                                valueExpr="id"
                                displayExpr="name"></dxo-lookup>
                </dxi-column>

                <dxi-column [caption]="'carrierDetermination.menu.percentage.option' | translate"
                            [setCellValue]="resetCarrierProductCell"
                            [editorOptions]="{ showClearButton: true }"
                            dataField="deliveryOption">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-header-filter [dataSource]="deliveryLocationStore"></dxo-header-filter>
                    <dxo-lookup [dataSource]="deliveryLocationStore"
                                displayExpr="text"
                                valueExpr="value">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateCarrierProductFilterExpression"
                            dataField="carrierProductId"
                            [setCellValue]="resetCarrierProductCell"
                            [editorOptions]="{ showClearButton: true }"
                            [caption]="'carrierDetermination.menu.percentage.carrierProduct' | translate">
                    <dxo-lookup [dataSource]="getFilteredCarrierProducts"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [caption]="'carrierDetermination.menu.percentage.salesChannel' | translate"
                            [editorOptions]="{ showClearButton: true }"
                            [width]="140"
                            dataField="salesChannel">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="salesChannelStore"
                                displayExpr="text"
                                valueExpr="value">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column dataField="maximum"
                            dataType="number"
                            [editorOptions]="{ format: 'fixedPoint', showClearButton: true }"
                            [caption]="'carrierDetermination.menu.percentage.maximum' | translate">
                </dxi-column>

                <dxi-column dataField="percentageAllocation"
                            dataType="number"
                            [editorOptions]="{ format: 'fixedPoint', showClearButton: true }"
                            [caption]="'carrierDetermination.menu.percentage.percentageAllocation' | translate">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxi-validation-rule type="range" min="0" max="100"></dxi-validation-rule>
                </dxi-column>

                <dxi-column dataField="activeSlotStart" editCellTemplate="timeTemplate" dataType="time"
                            [caption]="'carrierDetermination.menu.percentage.activeSlotStart' | translate"
                            [allowFiltering]="false">
                </dxi-column>

                <dxi-column dataField="activeSlotEnd" editCellTemplate="timeTemplate" dataType="time"
                            [caption]="'carrierDetermination.menu.percentage.activeSlotEnd' | translate"
                            [allowFiltering]="false">
                </dxi-column>

                <div *dxTemplate="let cell of 'timeTemplate'">
                    <dx-date-box
                        [(value)]=cell.value
                        type="time"
                        pickerType="native"
                        (onValueChanged)="cell.setValue($event.value)">
                    </dx-date-box>
                </div>

                <dxi-column type="buttons" [width]="110">
                    <dxi-button name="clone" cssClass="fas fa-copy" [onClick]="cloneRow"></dxi-button>
                    <dxi-button name="edit" cssClass="fas fa-pencil-alt"></dxi-button>
                    <dxi-button name="delete" cssClass="fas fa-delete-alt" [onClick]="delete"></dxi-button>
                    <dxi-button name="save" cssClass="fas fa-save"></dxi-button>
                    <dxi-button name="cancel" cssClass="fas fa-ban"></dxi-button>
                </dxi-column>
            </dx-data-grid>
        </div>

    </div>

</div>

<div class="page px-5">

    <div class="page__headline"
         eciTranslate="closureJobs.title">
        Closure jobs
    </div>

    <div class="row">

        <div class="col-md-12">
            <dx-data-grid (onInitNewRow)="onInitNewRow($event)"
                          (onRowUpdating)="onRowUpdate($event)"
                          (onToolbarPreparing)="onToolbarPreparing($event)"
                          (onEditorPreparing)="onEditorPreparing($event)"
                          [allowColumnReordering]="true"
                          [allowColumnResizing]="true"
                          [cellHintEnabled]="true"
                          [dataSource]="dataSource"
                          [hoverStateEnabled]="true"
                          [masterDetail]="{ enabled: true, template: 'detail' }"
                          [remoteOperations]="{ paging: true, filtering: true, sorting: true }"
                          [rowAlternationEnabled]="true"
                          [showBorders]="true"
                          [showColumnLines]="true"
                          [showRowLines]="true"
                          [wordWrapEnabled]="true">

                <dxo-column-chooser [enabled]="true"
                                    mode="dragAndDrop"></dxo-column-chooser>
                <dxo-header-filter [visible]="true"></dxo-header-filter>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showInfo]="true" [showPageSizeSelector]="true" [allowedPageSizes]="allowedPageSizes"
                           [visible]="true"></dxo-pager>
                <dxo-load-panel [enabled]="true"></dxo-load-panel>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxo-editing *eciHasAnyAuthority="Privilege.WriteClosureJob"
                             [allowUpdating]="true"
                             [allowDeleting]="true"
                             [allowAdding]="true"
                             [useIcons]="true"
                             mode="form">
                    <dxo-form [colCount]=1>
                        <dxi-item itemType="group" [colCount]=2>
                            <dxi-item dataField="system"></dxi-item>
                            <dxi-item dataField="warehouse"></dxi-item>
                            <dxi-item dataField="carrierId"></dxi-item>
                            <dxi-item dataField="shippingType"></dxi-item>
                            <dxi-item itemType="empty" [colSpan]=2></dxi-item>
                            <dxi-item dataField="stationId"></dxi-item>
                            <dxi-item dataField="clientIdOverride"></dxi-item>
                            <dxi-item dataField="email"></dxi-item>
                            <dxi-item dataField="active"></dxi-item>
                            <dxi-item dataField="cronSchedule"></dxi-item>
                        </dxi-item>

                        <dxi-item itemType="group" [colSpan]=2>
                            <dxi-item alignment="right" [template]="'formButtons'"></dxi-item>
                            <div *dxTemplate="let data of 'formButtons'">
                                <div class="modal-footer">
                                    <dx-check-box [text]="'shared.forms.createAnother' | translate"
                                                  [(value)]="createAnother" class="me-3"></dx-check-box>
                                    <button (click)="cancel()" [disabled]="isSaving" class="btn btn-secondary me-2" role="button" type="button">
                                        <i aria-hidden="true" class="fas fa-ban me-1"></i>
                                        <span eciTranslate="shared.forms.cancel">Cancel</span>
                                    </button>
                                    <button (click)="save()" [disabled]="isSaving" class="btn btn-primary me-2" role="button" type="submit">
                                        <i *ngIf="!isSaving" aria-hidden="true" class="fas fa-save me-1"></i>
                                        <i *ngIf="isSaving" aria-hidden="true" class="fas fa-spinner fa-spin fa-fw me-1"></i> <span
                                            eciTranslate="shared.forms.save">Save</span>
                                    </button>
                                </div>
                            </div>
                        </dxi-item>
                    </dxo-form>
                </dxo-editing>

                <div *dxTemplate="let data of 'gridHeader'">
                    <div class="d-flex align-items-left">
                        <dx-button stylingMode="outlined"
                                   icon="fa-sharp fa-solid fa-filter-circle-xmark"
                                   type="danger"
                                   (onClick)="resetFilter()">
                        </dx-button>
                    </div>
                </div>

                <!-- Visible while in development - can be disabled later-->
                <dxi-column [allowEditing]="false"
                            [allowHeaderFiltering]="false"
                            [caption]="'closureJobs.table.headers.id' | translate"
                            [visible]="false"
                            dataField="id"
                            sortOrder="asc">
                </dxi-column>

                <dxi-column dataField="system"
                            [caption]="'closureJobs.table.headers.system' | translate">
                    <dxo-lookup [dataSource]="service" valueExpr="name" displayExpr="name"></dxo-lookup>
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-column>

                <dxi-column [caption]="'closureJobs.table.headers.warehouse' | translate"
                            dataField="warehouse">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateCarrierFilterExpression"
                            [caption]="'carrierProducts.table.headers.carrier' | translate"
                            dataField="carrierId">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="carrierStore"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column [caption]="'closureJobs.table.headers.shippingType' | translate"
                            dataField="shippingType">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-column>

                <dxi-column [caption]="'closureJobs.table.headers.stationId' | translate"
                            dataField="stationId">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-column>

                <dxi-column [caption]="'closureJobs.table.headers.clientIdOverride' | translate"
                            dataField="clientIdOverride">
                </dxi-column>

                <dxi-column [caption]="'closureJobs.table.headers.cronSchedule' | translate"
                            cellTemplate="cronScheduleTemplate"
                            dataField="cronSchedule"
                            editCellTemplate="cronScheduleEditorTemplate">
                </dxi-column>

                <dxi-column [caption]="'closureJobs.table.headers.email' | translate"
                            dataField="email">
                </dxi-column>

                <dxi-column [caption]="'closureJobs.table.headers.active' | translate"
                            dataField="active"
                            dataType="boolean">
                </dxi-column>

                <dxi-column [width]="120"
                            type="buttons">
                    <dxi-button [hint]="'closureJobs.table.buttons.executeJob' | translate"
                                [onClick]="executeJob"
                                cssClass="fas fa-bolt"></dxi-button>

                    <dxi-button cssClass="fas fa-pencil-alt"
                                name="edit"></dxi-button>

                    <dxi-button cssClass="fas fa-save"
                                name="save"></dxi-button>

                    <dxi-button cssClass="fas fa-ban"
                                name="cancel"></dxi-button>

                    <dxi-button cssClass="fas fa-trash-alt"
                                name="delete" [onClick]="delete"></dxi-button>
                </dxi-column>

                <div *dxTemplate="let cell of 'cronScheduleTemplate'">
                    <kep-cron-schedule [value]="cell.value"></kep-cron-schedule>
                </div>

                <div *dxTemplate="let cell of 'cronScheduleEditorTemplate'">
                    <kep-cron-schedule-editor (ngModelChange)="cell.setValue($event)" [ngModel]="cell.value"></kep-cron-schedule-editor>
                </div>

                <div *dxTemplate="let cell of 'detail'">
                    <div class="master-detail-caption">{{ 'closureJobs.detailTable.title' | translate }}:</div>

                    <dx-data-grid [allowColumnReordering]="true"
                                  [allowColumnResizing]="true"
                                  [cellHintEnabled]="true"
                                  [columnAutoWidth]="true"
                                  [dataSource]="createDetailDataSource(cell.data.id)"
                                  [remoteOperations]="false"
                                  [showBorders]="true"
                                  [showColumnLines]="false"
                                  [showRowLines]="true">

                        <dxo-paging [pageSize]="5"></dxo-paging>

                        <dxi-column [caption]="'closureJobs.detailTable.headers.executedAt' | translate"
                                    dataField="executedAt"
                                    dataType="datetime"
                                    sortOrder="desc">
                        </dxi-column>

                        <dxi-column [caption]="'closureJobs.detailTable.headers.triggeredBy' | translate"
                                    dataField="triggeredBy">
                        </dxi-column>

                        <dxi-column [caption]="'closureJobs.detailTable.headers.status' | translate"
                                    dataField="status">
                        </dxi-column>

                        <dxi-column [caption]="'closureJobs.detailTable.headers.data' | translate"
                                    dataField="data">
                        </dxi-column>
                    </dx-data-grid>
                </div>
            </dx-data-grid>
        </div>
    </div>
</div>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import DataSource from 'devextreme/data/data_source';
import { EciDxService } from '@shared-components/shopsys-commons-ui';
import CustomStore from 'devextreme/data/custom_store';
import { DxUtil } from '../shared/util/dx.util';
import { CommonService } from '../shared/util/common.service';

@Injectable()
export class CountriesService {

    private readonly resourceUrl = 'api/countries';

    constructor(
        private http: HttpClient,
        private dxService: EciDxService,
        private commonService: CommonService,
    ) { }

    getCountries(): DataSource {
        return this.commonService.getDataSource(this.resourceUrl);
    }

    getCountriesAsCustomStore(): CustomStore {
        return new CustomStore({
            key: 'id',
            load: loadOptions => this.dxService.query(this.resourceUrl, DxUtil.loadAll(loadOptions))
                .then(x => x.data),
            byKey: id => this.http.get(`${this.resourceUrl}/${id}`).toPromise(),
        });
    }

}

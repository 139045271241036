import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { LOCALE_ID, NgModule, TRANSLATIONS, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Route, RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import DataGrid from 'devextreme/ui/data_grid';
import {
    DxChartModule,
    DxDataGridModule,
    DxDateBoxModule,
    DxFormModule,
    DxSelectBoxModule,
    DxCheckBoxModule,
    DxTagBoxModule,
    DxButtonModule,
    DxFilterBuilderModule
} from 'devextreme-angular';
import { CarriersModule } from './carriers/carriers.module';
import { CarriersComponent } from './carriers/carriers.component';
import { CarrierProductsModule } from './carrier-products/carrier-products.module';
import { CarrierProductsComponent } from './carrier-products/carrier-products.component';
import { CountriesComponent } from './countries/countries.component';
import { CountriesModule } from './countries/countries.module';
import { WarehouseModule } from './warehouse/warehouse.module';
import { WarehouseComponent } from './warehouse/warehouse.component';
import { CptComponent } from './cpt/cpt.component';
import { CptModule } from './cpt/cpt.module';
import { ArticleBrickCodeRulesModule } from './article-brick-code-rules/article-brick-code-rules.module';
import { ArticleBrickCodeRulesComponent } from './article-brick-code-rules/article-brick-code-rules.component';
import { ArticleRulesModule } from './article-rules/article-rules.module';
import { ArticleRulesComponent } from './article-rules/article-rules.component';
import { ArticleServicesRulesModule } from './article-services-rules/article-services-rules.module';
import { ArticleServicesRulesComponent } from './article-services-rules/article-services-rules.component';
import { CronScheduleComponent } from './shared/components/cron-schedule/cron-schedule.component';
import { CronScheduleEditorComponent } from './shared/components/cron-schedule/cron-schedule-editor/cron-schedule-editor.component';
import { CarrierDeterminationModule } from './carrier-determination/carrier-determination.module';
import { ConfigurationModule } from './configuration/configuration.module';
import { ClosureJobsModule } from './configuration/closure-jobs/closure-jobs.module';
import { ClosureJobsComponent } from './configuration/closure-jobs/closure-jobs.component';
import { PackageMaterialDefinitionsModule } from './configuration/package-material-definitions/package-material-definitions.module';
import { PackageMaterialDefinitionsComponent } from './configuration/package-material-definitions/package-material-definitions.component';
import { LabelServiceConfigComponent } from './configuration/label-service-config/label-service-config.component';
import { SendItLabelAndClosureConfigurationComponent } from './configuration/send-it-label-and-closure-configuration/send-it-label-and-closure-configuration.component';
import { PadStartPipe } from './shared/pipes/left-pad.pipe';

import {
    activationRoute,
    adminState,
    ECI_ERROR_HANDLER_FACTORY,
    ECI_ERROR_HANDLER_TOKEN,
    errorRoute,
    loginRoute,
    ModuleConfig,
    passwordResetRoute,
    settingsRoute,
    ShopsysCommonsModule,
    SHOPSYS_COMMONS_UI_TRANSLATIONS,
    tenantAwareTranslateLoader,
    tenantSwitchRoute,
    ECI_DEFAULT_ERROR_HANDLERS
} from '@shared-components/shopsys-commons-ui';
import { _VERSION } from './build.constants';
import { HomeComponent, HOME_ROUTE } from './home';
import { KepMainComponent } from './main/main.component';
import { CARRIERS_ROUTE } from './carriers/carriers.route';
import { CARRIER_PRODUCTS_ROUTE } from './carrier-products/carrier-products.route';
import { COUNTRIES_ROUTE } from './countries/countries.route';
import { WAREHOUSE_ROUTE } from './warehouse/warehouse.route';
import { CPT_ROUTE } from './cpt/cpt.route';
import { ARTICLE_BRICK_CODE_RULES_ROUTE } from './article-brick-code-rules/article-brick-code-rules.route';
import { ARTICLE_RULES_ROUTE } from './article-rules/article-rules.route';
import { ARTICLE_SERVICES_RULES_ROUTE } from './article-services-rules/article-services-rules.route';
import { CarrierProductDistributionTimesComponent } from './carrier-product-distribution-times/carrier-product-distribution-times.component';
import { CarrierProductDistributionTimesModule } from './carrier-product-distribution-times/carrier-product-distribution-times.module';
import { CARRIER_PRODUCT_DISTRIBUTION_TIMES_ROUTE } from './carrier-product-distribution-times/carrier-product-distribution-times.route';
import { CARRIER_DETERMINATION_ROUTE } from './carrier-determination/carrier-determination.route';
import { CARRIER_PERCENTAGE_ROUTE } from './carrier-determination/carrier-percentage/carrier-percentage.route';
import { SENDIT_LABEL_AND_CLOSURE_CONFIG } from './configuration/send-it-label-and-closure-configuration/send-it-label-and-closure-configuration.route';
import { PACKAGE_MATERIAL_DEFINITIONS_ROUTE } from './configuration/package-material-definitions/package-material-definitions.route';
import { LABEL_SERVICE_CONFIG_ROUTE } from './configuration/label-service-config/label-service-config.route';
import { CLOSURE_JOBS_ROUTE } from './configuration/closure-jobs/closure-jobs.route';
import { POST_CODE_LISTS_ROUTE } from './postcodelists/post-code-lists.route';
import { AUDITS_ROUTE } from './audits/audits.route';
import { AuditsModule } from './audits/audits.module';
import { AuditsComponent } from './audits/audits.component';
import { BackupShippingTypeAddressesComponent } from './configuration/backup-shipping-type-addresses/backup-shipping-type-addresses.component';
import { BACKUP_SHIPPING_TYPE_ADDRESS_ROUTE } from './configuration/backup-shipping-type-addresses/backup-shipping-type-addresses.route';
import { de } from '../i18n/de.translation';
import { en } from '../i18n/en.translation';
import { POST_CODE_ROUTE } from './postcodelists/postcode/post-code.route';
import { PostCodeListsModule } from './postcodelists/post-code-lists.module';
import { PostCodeListsComponent } from './postcodelists/post-code-lists.component';
import { CARRIER_POST_CODE_LIST_ROUTE } from './carrier-determination/carrier-post-code-list/carrier-post-code-list.route';
import { AdditionalServicesComponent } from './additional-services/additional-services.component';
import { ADDITIONAL_SERVICES_ROUTE } from './additional-services/additional-services.route';
import { AdditionalServicesModule } from './additional-services/additional-services.module';
import { CarrierProductsAvailabilityModule } from './carrier-products-availability/carrier-products-availability.module';
import {
    CARRIER_PRODUCTS_AVAILABILITY_ROUTE
} from './carrier-products-availability/carrier-products-availability.route';
import {
    CarrierProductsAvailabilityComponent
} from './carrier-products-availability/carrier-products-availability.component';
import { TRACKING_EVENT_ROUTE } from './tracking/tracking-event/tracking-event.route';
import { TrackingEventComponent } from './tracking/tracking-event/tracking-event.component';
import { TrackingModule } from './tracking/tracking.module';
import {
    TRACKING_EVENT_CODE_MAPPING_ROUTE
} from './tracking/tracking-event-code-mapping/tracking-event-code-mapping.route';
import {
    TrackingEventCodeMappingComponent
} from './tracking/tracking-event-code-mapping/tracking-event-code-mapping.component';
import { CARRIER_DOWNTIME } from './configuration/carrier-downtime/carrier-downtime.route';
import { CarrierDowntimeComponent } from './configuration/carrier-downtime/carrier-downtime.component';
import { CARRIER_PRICES_ROUTE } from './parcel-costs/carrier-prices/carrier-prices.route';
import { CarrierPricesComponent } from './parcel-costs/carrier-prices/carrier-prices.component';
import { ParcelCostsModule } from './parcel-costs/parcel-costs.module';
import { CURRENCY_EXCHANGE_RATES_ROUTE } from './parcel-costs/currency-exchange-rates/currency-exchange-rates.route';
import {
    CurrencyExchangeRatesComponent
} from './parcel-costs/currency-exchange-rates/currency-exchange-rates.component';
import { ActiveSessionsModule } from './active-sessions/active-sessions.module';
import { ActiveSessionsComponent } from './active-sessions/active-sessions.component';
import { ACTIVE_SESSIONS_ROUTE } from './active-sessions/active-sessions.route';

const ACCOUNT_ROUTES = [
    settingsRoute,
    loginRoute,
    activationRoute,
    passwordResetRoute,
];

const defaultRoute: Route = { path: '**', redirectTo: '/', pathMatch: 'full' };

export const routes: Routes = [
    HOME_ROUTE,
    CARRIERS_ROUTE,
    CARRIER_PRODUCTS_ROUTE,
    COUNTRIES_ROUTE,
    WAREHOUSE_ROUTE,
    CPT_ROUTE,
    ARTICLE_BRICK_CODE_RULES_ROUTE,
    ARTICLE_RULES_ROUTE,
    ARTICLE_SERVICES_RULES_ROUTE,
    CARRIER_PRODUCT_DISTRIBUTION_TIMES_ROUTE,
    CARRIER_DETERMINATION_ROUTE,
    CARRIER_PERCENTAGE_ROUTE,
    CARRIER_POST_CODE_LIST_ROUTE,
    POST_CODE_LISTS_ROUTE,
    POST_CODE_ROUTE,
    SENDIT_LABEL_AND_CLOSURE_CONFIG,
    BACKUP_SHIPPING_TYPE_ADDRESS_ROUTE,
    PACKAGE_MATERIAL_DEFINITIONS_ROUTE,
    LABEL_SERVICE_CONFIG_ROUTE,
    ADDITIONAL_SERVICES_ROUTE,
    CLOSURE_JOBS_ROUTE,
    CARRIER_PRODUCTS_AVAILABILITY_ROUTE,
    TRACKING_EVENT_ROUTE,
    TRACKING_EVENT_CODE_MAPPING_ROUTE,
    CARRIER_DOWNTIME,
    ACTIVE_SESSIONS_ROUTE,
    AUDITS_ROUTE,
    CARRIER_PRICES_ROUTE,
    CURRENCY_EXCHANGE_RATES_ROUTE,
    ...errorRoute,
    ...adminState,
    ...tenantSwitchRoute,
    {
        path: '',
        children: ACCOUNT_ROUTES,
    },
    defaultRoute,
];

// Register locale data for 'de'
registerLocaleData(localeDe, 'de');

/**
 * Root module of this application.
 *
 * The basic infrastructure is provided by importing 'ShopsysCommonsModule'.
 */
@NgModule({
    imports: [
        RouterModule.forRoot(routes),
        BrowserModule,
        NgbModule,
        // App-specific configuration can be provided here
        ShopsysCommonsModule.forRoot({
            version: _VERSION,
            defaultI18nLang: 'en-GB',
            availableLanguages: ['en-GB', 'de-DE'],
            storagePrefix: 'kep',
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: tenantAwareTranslateLoader,
                deps: [HttpClient, ModuleConfig],
            },
        }),
        DxDataGridModule,
        DxFormModule,
        DxDateBoxModule,
        DxChartModule,
        DxSelectBoxModule,
        DxCheckBoxModule,
        DxTagBoxModule,
        DxButtonModule,
        CarriersModule,
        CarrierProductsModule,
        CountriesModule,
        WarehouseModule,
        CptModule,
        ArticleBrickCodeRulesModule,
        ArticleRulesModule,
        ArticleServicesRulesModule,
        CarrierProductDistributionTimesModule,
        CarrierDeterminationModule,
        ConfigurationModule,
        ClosureJobsModule,
        PackageMaterialDefinitionsModule,
        AuditsModule,
        PostCodeListsModule,
        AdditionalServicesModule,
        DxFilterBuilderModule,
        CarrierProductsAvailabilityModule,
        TrackingModule,
        ParcelCostsModule,
        ActiveSessionsModule,
    ],
    declarations: [
        KepMainComponent,
        HomeComponent,
        CarriersComponent,
        CountriesComponent,
        CarrierProductsComponent,
        WarehouseComponent,
        CptComponent,
        ArticleBrickCodeRulesComponent,
        CronScheduleComponent,
        CronScheduleEditorComponent,
        ArticleRulesComponent,
        ArticleServicesRulesComponent,
        CarrierProductDistributionTimesComponent,
        ClosureJobsComponent,
        PackageMaterialDefinitionsComponent,
        LabelServiceConfigComponent,
        BackupShippingTypeAddressesComponent,
        SendItLabelAndClosureConfigurationComponent,
        PadStartPipe,
        AuditsComponent,
        PostCodeListsComponent,
        AdditionalServicesComponent,
        CarrierProductsAvailabilityComponent,
        TrackingEventComponent,
        TrackingEventCodeMappingComponent,
        CarrierDowntimeComponent,
        CarrierPricesComponent,
        CurrencyExchangeRatesComponent,
        ActiveSessionsComponent,
    ],
    bootstrap: [KepMainComponent],
    providers: [
        { provide: LOCALE_ID, useValue: 'de' },
        {
            provide: TRANSLATIONS,
            useValue: [
                SHOPSYS_COMMONS_UI_TRANSLATIONS,
                {
                    'de-DE': de,
                    'en-GB': en,
                }],
        },
        { provide: ECI_ERROR_HANDLER_TOKEN, useFactory: ECI_ERROR_HANDLER_FACTORY, deps: ECI_DEFAULT_ERROR_HANDLERS },
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
    ],
})
export class KepAppModule {
    constructor() {
        DataGrid.defaultOptions({
            options: {
                editing: {
                    texts: {
                        // This prevents the default delete dialogue from being shown
                        confirmDeleteMessage: '',
                    },
                },

            },
        });
    }
}

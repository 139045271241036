<div class="page px-5" xmlns="http://www.w3.org/1999/html">

    <div class="page__headline" eciTranslate="carriers.title">
        Carriers
    </div>

    <div class="row justify-content-center" *eciHasAnyAuthority="Privilege.ReadMasterData">

        <div class="col-sm-8">
            <dx-data-grid [allowColumnReordering]="true"
                          [allowColumnResizing]="true"
                          [cellHintEnabled]="true"
                          [dataSource]="dataSource"
                          [hoverStateEnabled]="true"
                          [showBorders]="true"
                          [showColumnLines]="true"
                          [showRowLines]="false"
                          [wordWrapEnabled]="true"
                          [rowAlternationEnabled]="true"
                          [remoteOperations]="{ filtering: true, sorting: true, paging: true }"
                          (onRowUpdating)="rowUpdate($event)"
                          (onToolbarPreparing)="onToolbarPreparing($event)"
                          (onEditorPreparing)="onEditorPreparing($event)">
                <dxo-column-chooser
                    [enabled]="true"
                    mode="dragAndDrop">
                </dxo-column-chooser>

                <dxo-header-filter [visible]="true"
                                   [allowSearch]="true">
                </dxo-header-filter>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showInfo]="true" [showPageSizeSelector]="true" [visible]="true"
                           [allowedPageSizes]="allowedPageSizes"></dxo-pager>
                <dxo-load-panel [enabled]="true"></dxo-load-panel>
                <dxo-editing
                    *eciHasAnyAuthority="Privilege.WriteCarrier"
                    mode="row"
                    [useIcons]="true"
                    [allowUpdating]="true"
                    [allowDeleting]="true"
                    [allowAdding]="true">
                </dxo-editing>

                <div *dxTemplate="let data of 'gridHeader'">
                    <div class="d-flex align-items-left">
                        <dx-button stylingMode="outlined"
                                   icon="fa-sharp fa-solid fa-filter-circle-xmark"
                                   type="danger"
                                   (onClick)="resetFilter()">
                        </dx-button>
                    </div>
                </div>

                <dxi-column dataField="id"
                            caption="{{ 'carriers.table.headers.id' | translate }}"
                            [allowEditing]="false"
                            [visible]="false">
                </dxi-column>

                <dxi-column [calculateFilterExpression]="calculateCountryFilterExpression"
                            width="200"
                            dataField="countryId"
                            sortOrder="asc"
                            calculateSortValue="countryName"
                            caption="{{ 'carriers.table.headers.country' | translate }}">
                    <dxo-header-filter [allowSearch]="true"></dxo-header-filter>
                    <dxo-lookup [dataSource]="countriesStore"
                                displayExpr="name"
                                valueExpr="id">
                    </dxo-lookup>
                </dxi-column>

                <dxi-column dataField="kepId"
                            width="200"
                            caption="{{ 'carriers.table.headers.kepId' | translate }}">
                </dxi-column>

                <dxi-column dataField="name"
                            caption="{{ 'carriers.table.headers.name' | translate }}">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-column>

                <dxi-column dataField="description"
                            [allowHeaderFiltering]="false"
                            caption="{{ 'carriers.table.headers.description' | translate }}">
                </dxi-column>

                <dxi-column type="buttons" [width]="110">
                    <dxi-button name="edit" cssClass="fas fa-pencil-alt"></dxi-button>
                    <dxi-button name="save" cssClass="fas fa-save"></dxi-button>
                    <dxi-button name="cancel" cssClass="fas fa-ban"></dxi-button>
                    <dxi-button name="delete" cssClass="fas fa-trash-alt" [onClick]="delete"></dxi-button>
                </dxi-column>

            </dx-data-grid>

        </div>

    </div>

</div>

<div class="page px-5">

    <div class="page__headline"
         eciTranslate="packageMaterialDefinitions.title">
        Package Material Definitions
    </div>

    <div class="row justify-content-center">

        <div class="col-sm-6">
            <dx-data-grid (onRowUpdating)="onRowUpdate($event)"
                          (onToolbarPreparing)="onToolbarPreparing($event)"
                          (onEditorPreparing)="onEditorPreparing($event)"
                          [allowColumnReordering]="true"
                          [allowColumnResizing]="true"
                          [cellHintEnabled]="true"
                          [dataSource]="dataSource"
                          [hoverStateEnabled]="true"
                          [remoteOperations]="{ paging: true, filtering: true, sorting: true }"
                          [rowAlternationEnabled]="true"
                          [showBorders]="true"
                          [showColumnLines]="true"
                          [showRowLines]="true"
                          [wordWrapEnabled]="true">

                <dxo-column-chooser [enabled]="true"
                                    mode="dragAndDrop"></dxo-column-chooser>
                <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showInfo]="true" [showPageSizeSelector]="true" [allowedPageSizes]="allowedPageSizes"
                           [visible]="true"></dxo-pager>
                <dxo-load-panel [enabled]="true"></dxo-load-panel>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxo-editing *eciHasAnyAuthority="Privilege.WritePackageMaterialDefinition"
                             [allowUpdating]="true"
                             [allowDeleting]="true"
                             [allowAdding]="true"
                             [useIcons]="true"
                             mode="form">

                    <dxo-form [colCount]=1>
                        <dxi-item itemType="group" [colCount]=2>
                            <dxi-item dataField="packageMaterial"></dxi-item>
                            <dxi-item dataField="volume"></dxi-item>
                        </dxi-item>

                        <dxi-item itemType="group" [colSpan]=2>
                            <dxi-item alignment="right" [template]="'formButtons'"></dxi-item>
                            <div *dxTemplate="let data of 'formButtons'">
                                <div class="modal-footer">
                                    <dx-check-box [text]="'shared.forms.createAnother' | translate" [(value)]="createAnother"></dx-check-box>
                                    <button (click)="cancel()" [disabled]="isSaving" class="btn btn-secondary" role="button" type="button">
                                        <i aria-hidden="true" class="fas fa-ban me-1"></i>
                                        <span eciTranslate="shared.forms.cancel">Cancel</span>
                                    </button>
                                    <button (click)="save()" [disabled]="isSaving" class="btn btn-primary" role="button" type="submit">
                                        <i *ngIf="!isSaving" aria-hidden="true" class="fas fa-save me-1"></i>
                                        <i *ngIf="isSaving" aria-hidden="true" class="fas fa-spinner fa-spin fa-fw me-1"></i> <span
                                            eciTranslate="shared.forms.save">Save</span>
                                    </button>
                                </div>
                            </div>
                        </dxi-item>
                    </dxo-form>
                </dxo-editing>

                <div *dxTemplate="let data of 'gridHeader'">
                    <div class="d-flex align-items-left">
                        <dx-button stylingMode="outlined"
                                   icon="fa-sharp fa-solid fa-filter-circle-xmark"
                                   type="danger"
                                   (onClick)="resetFilter()">
                        </dx-button>
                    </div>
                </div>

                <!-- Visible while in development - can be disabled later-->
                <dxi-column [allowEditing]="false"
                            [allowHeaderFiltering]="false"
                            [caption]="'packageMaterialDefinitions.table.headers.id' | translate"
                            [visible]="false"
                            dataField="id"
                            sortOrder="asc">
                </dxi-column>

                <dxi-column [allowHeaderFiltering]="false"
                            [caption]="'packageMaterialDefinitions.table.headers.packageMaterial' | translate"
                            dataField="packageMaterial">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-column>

                <dxi-column [caption]="'packageMaterialDefinitions.table.headers.volume' | translate"
                            [editorOptions]="{ format: 'fixedPoint' }"
                            dataField="volume"
                            dataType="number">
                </dxi-column>

                <dxi-column type="buttons" [width]="110">
                    <dxi-button name="edit" cssClass="fas fa-pencil-alt"></dxi-button>
                    <dxi-button name="save" cssClass="fas fa-save"></dxi-button>
                    <dxi-button name="cancel" cssClass="fas fa-ban"></dxi-button>
                    <dxi-button name="delete" cssClass="fas fa-trash-alt" [onClick]="delete"></dxi-button>
                </dxi-column>

            </dx-data-grid>

        </div>

    </div>

</div>

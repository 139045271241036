import { Injectable } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { CommonService } from '../../shared/util/common.service';

@Injectable()
export class CurrencyExchangeRatesService {

    private readonly currencyExchangeRatesResourceUrl = 'api/currency-exchange-rates';

    constructor(
        private commonService: CommonService,
    ) {
    }

    getCurrencyExchangeRates(): DataSource {
        return this.commonService.getDataSource(this.currencyExchangeRatesResourceUrl);
    }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EciDxService, EciTenantAwareLanguageService } from '@shared-components/shopsys-commons-ui';
import CustomStore from 'devextreme/data/custom_store';
import { DxUtil } from '../shared/util/dx.util';
import { CommonService } from '../shared/util/common.service';
@Injectable()
export class CarrierDeterminationService {

    private readonly resourceUrl = '/api/carrier-allocation';
    private readonly carrierPostCodeResourceUrl = '/api/carrier-post-code-list';

    constructor(
        private http: HttpClient,
        private translateService: EciTenantAwareLanguageService,
        private dxService: EciDxService,
        private commonService: CommonService,
    ) {}

    getCarrierRatio() {
        return this.commonService.getDataSource(this.resourceUrl);
    }

    getCarrierPostCodes() {
        return this.commonService.getDataSource(this.carrierPostCodeResourceUrl);
    }

    getCarrierPostcodeListsAsCustomStore(): CustomStore {
        return new CustomStore({
            key: 'id',
            load: loadOptions => this.dxService.query(this.carrierPostCodeResourceUrl, DxUtil.loadAll(loadOptions))
                .then(x => x.data),
            byKey: id => this.http.get(`${this.carrierPostCodeResourceUrl}/${id}`).toPromise(),
        });
    }

    getDeliveryOptions(): CustomStore {
        return new CustomStore({
            loadMode: 'raw',
            load: () => this.http.get<string[]>(`${this.resourceUrl}/delivery-options`).toPromise()
                .then(data => data.map(value => {
                    const text = this.translateService.get(`carrierDetermination.menu._deliveryOption.${value}`);
                    return { text, value };
                })),
        });
    }

    getPossibleCountries(warehouseIds: string[], carrierIds: string[]): CustomStore {
        return new CustomStore({
            key: 'id',
            loadMode: 'raw',
            load: () => this.http.get<any[]>(`${this.resourceUrl}/possible-countries?warehouseIds=` + warehouseIds.toString()
                + '&carrierIds=' + carrierIds.toString()).toPromise()
                .then(data => data.map(value => {
                    return { id: value[0], name: value[1] };
                })),
        });
    }

    getPossibleWarehouses(countryIds: string[], carrierIds: string[]): CustomStore {
        return new CustomStore({
            key: 'id',
            loadMode: 'raw',
            load: () => this.http.get<any[]>(`${this.resourceUrl}/possible-warehouses?countryIds=` + countryIds.toString()
                + '&carrierIds=' + carrierIds.toString()).toPromise()
                .then(data => data.map(value => {
                    return { id: value[0], name: value[1] };
                })),
        });
    }

    getPossibleCarriers(countryIds: string[], warehousesIds: string[]): CustomStore {
        return new CustomStore({
            key: 'id',
            loadMode: 'raw',
            load: () => this.http.get<any[]>(`${this.resourceUrl}/possible-carriers?countryIds=` + countryIds.toString()
                + '&warehouseIds=' + warehousesIds.toString()).toPromise()
                .then(data => data.map(value => {
                    return { id: value[0], name: value[1] };
                })),
        });
    }

    getSalesChannels(): CustomStore {
        return this.commonService.getCustomSalesChannels();
    }
}

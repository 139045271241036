import { Component, OnInit } from '@angular/core';
import { CommonView } from '../../shared/util/common-view.util';
import DataSource from 'devextreme/data/data_source';
import { Privilege } from '../../shared/models/privilege.enum';
import { DxLookupDataSourceOptions, DxRowUpdateEvent, DxUtil } from '../../shared/util/dx.util';
import { TrackingService } from '../tracking.service';
import { CarriersService } from '../../carriers/carriers.service';
import { CountriesService } from '../../countries/countries.service';
import CustomStore from 'devextreme/data/custom_store';
import { Country } from '../../shared/models/country.model';
import { DxFilter } from '@shared-components/shopsys-commons-ui';
import { TrackingEventCodeMapping } from '../../shared/models/tracking-event-code-mapping.model';
import { ShipmentDirection } from '../../shared/models/shipment-direction.model';
import { CommonService } from '../../shared/util/common.service';

@Component({
    selector: 'kep-tracking-event-code-mapping',
    templateUrl: './tracking-event-code-mapping.component.html',
})
export class TrackingEventCodeMappingComponent extends CommonView implements OnInit {

    Privilege = Privilege;
    dataSource: DataSource;
    countryStore: CustomStore;
    carrierStore: CustomStore;
    eventCustomStore: CustomStore;
    trackingEventCustomStore: CustomStore;
    shipmentDirections: ShipmentDirection[];
    subEventCustomStore: CustomStore;

    selectedEvents = [];
    selectedSubEvents = [];
    selectedCountries = [];
    selectedCarriers = [];

    readonly allowedPageSizes = [10, 20, 50, 75, 100, 1000];

    resetCarrierIdCell = DxUtil.hookSetCellValue<TrackingEventCodeMapping>(rowData => {
        rowData.carrierId = null;
    });

    resetTrackingSubEventCell = DxUtil.hookSetCellValue<TrackingEventCodeMapping>(rowData => {
        rowData.trackingSubEvent = null;
    });

    constructor(
        private trackingService: TrackingService,
        private carrierService: CarriersService,
        private countriesService: CountriesService,
        private commonService: CommonService,
    ) {
        super();
    }

    resetFilter(): void {
        super.resetFilter();

    }

    ngOnInit() {
        this.dataSource = this.trackingService.getTrackingEventCodeMappings();
        this.countryStore = this.countriesService.getCountriesAsCustomStore();
        this.carrierStore = this.carrierService.getCarriersAsCustomStore();
        this.shipmentDirections = this.commonService.getShipmentDirection();
        this.eventCustomStore = this.trackingService.getAllEvents();
        this.trackingEventCustomStore = this.trackingService.getTrackingEventsAsCustomStore();
        this.reloadFilter();
    }

    onRowUpdate(e: DxRowUpdateEvent<TrackingEventCodeMapping>) {
        DxUtil.fillUpNewDataOnUpdate(e);
    }

    initNewRow(e) {
        if (this.currentRowData === null) {
            e.data = {
                dropshipper: false,
            };
        } else {
            e.data = this.currentRowData;
        }
    }

    calculateCountryFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['country.id', filterOperation, filterValue];
    }

    calculateCarrierFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['carrier.id', filterOperation, filterValue];
    }

    calculateTrackingEventFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['trackingEvent.event', filterOperation, filterValue];
    }

    calculateTrackingSubEventFilterExpression(filterValue: string, filterOperation: string): string[] {
        return ['trackingEvent.subEvent', filterOperation, filterValue];
    }

    getFilteredCarriers = (options: DxLookupDataSourceOptions<TrackingEventCodeMapping>) => {
        if (options.data == null) {
            return { store: this.carrierStore };
        }

        const country = DxUtil.fetchLookupObject<TrackingEventCodeMapping, Country>(options, 'countryId');

        const filter = DxFilter.equals('country.id', country ? country.id : null);

        return {
            store: this.carrierStore,
            filter,
        };
    };

    getFilteredSubEvents = (options: DxLookupDataSourceOptions<TrackingEventCodeMapping>) => {
        if (options.data == null) {
            return { store: this.trackingEventCustomStore };
        }

        const filter = DxFilter.equals('event', options.data ? options.data.trackingEvent : null);

        return {
            store: this.trackingEventCustomStore,
            filter,
        };
    };

    onFilterChange() {
        this.dataGrid.instance.clearFilter();
        this.reloadFilter();

        this.dataGrid.instance.columnOption('countryId', 'filterValues', this.selectedCountries);
        this.dataGrid.instance.columnOption('carrierId', 'filterValues', this.selectedCarriers);
        this.dataGrid.instance.columnOption('trackingEvent', 'filterValues', this.selectedEvents);
        this.dataGrid.instance.columnOption('trackingSubEvent', 'filterValues', this.selectedSubEvents);
    }

    reloadFilter() {
        console.log('reloadFilter');
        this.subEventCustomStore = this.trackingService.getAllSubEvents(this.selectedEvents);
    }
}

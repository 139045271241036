import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import DataSource from 'devextreme/data/data_source';
import { EciDxService, EciTenantAwareLanguageService } from '@shared-components/shopsys-commons-ui';
import { CommonService } from '../../shared/util/common.service';
import CustomStore from 'devextreme/data/custom_store';
import { DxUtil } from '../../shared/util/dx.util';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class CarrierPricesService {

    private readonly carrierPricesResourceUrl = 'api/carrier-prices';

    constructor(
        private http: HttpClient,
        private dxService: EciDxService,
        private commonService: CommonService,
    ) {
    }

    getCarrierPrices(): DataSource {
        return this.commonService.getDataSource(this.carrierPricesResourceUrl);
    }

    getCarrierPricesAsCustomStore(): CustomStore {
        return new CustomStore({
            key: 'id',
            loadMode: 'raw',
            load: loadOptions => this.dxService.query(this.carrierPricesResourceUrl, DxUtil.loadAll(loadOptions))
                .then(x => x.data),
        });
    }

    getCustomRules(): CustomStore {
        return new CustomStore({
            loadMode: 'raw',
            load: () => firstValueFrom(this.http.get<string[]>('/api/carrier-prices/rules'))
                .then(data => data.map(value => {
                    return { value };
                })),
        });
    }

    getCustomVolumetricWeightFormulas(): CustomStore {
        return new CustomStore({
            loadMode: 'raw',
            load: () => firstValueFrom(this.http.get<string[]>('/api/carrier-prices/formulas'))
                .then(data => data.map(value => {
                    return { value };
                })),
        });
    }

    getCustomCurrencies(): CustomStore {
        return new CustomStore({
            loadMode: 'raw',
            load: () => firstValueFrom(this.http.get<string[]>('/api/carrier-prices/currencies'))
                .then(data => data.map(value => {
                    return { value };
                })),
        });
    }

    getCustomPackageTypes(): CustomStore {
        return new CustomStore({
            loadMode: 'raw',
            load: () => firstValueFrom(this.http.get<string[]>('/api/carrier-prices/package-types'))
                .then(data => data.map(value => {
                    return { value };
                })),
        });
    }
}
